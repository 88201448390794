<template>
  <div class="flex justify-end bg-white rounded-md p-2">
    <div class="atras flex align-middle">
      <i class="pi pi-angle-left text-blue-900 my-auto cursor-pointer"
         style="font-size: 1.5rem"
         @click="$router.go(-1)"
      />
    </div>
    <div class="text-[#354357] font-bold my-auto" >
      <h2 class="text-lg">
        Solicitudes
      </h2>
    </div>
    <div class="p-inputgroup">
    </div>
    <div v-if="$can('pharmasan.administrativa.juridica.solicitudes.access')" class="crear flex justify-end" style="min-width: 244px">
      <Button label="Crear un nueva solicitud" class="bg-blue-900" @click="goto('pharmasan.administrativa.juridica.solicitudes.crear')"/>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'

export default {
  name: 'SolicitudesMainListadoHeader',
  setup () {
    const router = useRouter()
    const goto = (name) => {
      router.push({ name })
    }
    return {
      goto
    }
  }
}
</script>

<style scoped>

</style>
